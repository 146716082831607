<template>
    <section class="login-now" loding="lazy">
        <div class="wrapper">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="loginInner1" v-if="siteSettings">
                            <div class="log-logo">
                                <img :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo"
                                    alt="logo">
                            </div>
                            <div class="featured-box-login">
                                <h1>{{ translatedLangData('log-in','Login')}}<i class="fas fa-hand-point-down"></i></h1>
                                <!-- <div role="alert" class="alert alert-danger" v-if="showUserIdValidationError || showPasswordValidationError">
                                    <ul>
                                        <li v-if="showUserIdValidationError">Please Enter {{ form.type == 1 ? 'Mobile Number' :'User ID' }}</li>
                                        <li v-if="showPasswordValidationError">Please Enter Password</li>
                                    </ul>
                                </div> -->
                                <form class="form-control-head">

                                    <!-- <div class="get-mob">
                                        <div class="row">
                                            <div class="col-3" v-if="siteSettings.business_type == 2">
                                                <div class="col-auto">
                                                    <select class="form-select" id="autoSizingSelect" v-model="form.type">
                                                        <option value="1">Phone</option>
                                                        <option value="2">user id</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-3" v-if="siteSettings.business_type == 1">
                                                <div class="col-auto">
                                                    <div class="form-control">
                                                        <span>user id</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-9">
                                                <div class="col-auto inser-v-inpt-box">
                                                    <input :type="form.type == 1 ? 'number' : 'text'" class="form-control add" id="autoSizingInput" :placeholder="form.type == 1 ? 'Mobile No.': 'Enter ID'" v-model="form.userId" />
                                                </div>
                                            </div>
                                            <div v-if="form.type == 1" class="phone-country demo col-2">
                                                <div class="mm-dropdown-con ">
                                                    <select v-model="form.code" class="form-select textfirst-con "
                                                        aria-label="Default select example">
                                                        <option :value="allowedCountry.phonecode"
                                                            v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                            :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="login-tab-sec">
                                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" v-if="siteSettings?.business_type == 2">
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" :class="form.type == 1 ? 'active': ''" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#phone-num" type="button" role="tab"
                                                    aria-controls="phone-num" aria-selected="true" @click="()=>{ form.mobileNo=''; form.type = 1}">{{ translatedLangData('mobile-number','Mobile Number')}}</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" :class="form.type == 2 ? 'active': ''" id="user-id-tab" data-bs-toggle="pill" data-bs-target="#user-id" type="button" role="tab"
                                                    aria-controls="user-id" aria-selected="false" @click="()=>{ form.userId=''; form.type = 2}">{{ translatedLangData('user-id','User ID')}}</button>
                                            </li>
                                        </ul>
                                        <div class="tab-content" id="pills-tabContent" v-if="siteSettings?.business_type == 2">

                                            <div class="tab-pane fade" :class="form.type == 1 ? 'show active': ''" id="phone-num" role="tabpanel" aria-labelledby="phone-num-tab">
                                                <div class="mobile-input-h">
                                                    <!-- <label for="">Mobile Number</label> -->
                                                </div>
                                                <div class="get-mob">
                                                    <div class="row">
                                                        <div class="col-3">
                                                            <div class="col-auto">
                                                                <select v-model="form.code" class="form-select" aria-label="Default select example">
                                                                    <option :value="allowedCountry.phonecode"
                                                                        v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                                        :key="countryIndex" :disabled="allowedCountry.phonecode!='91'">+{{ allowedCountry.phonecode }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-9">
                                                            <div class="col-auto">
                                                                <input type="tel" class="form-control add"
                                                                    id="autoSizingInput" :placeholder="translatedLangData('mobile-number','Mobile Number')+'*'" maxlength="10"
                                                                    v-model="form.mobileNo" @input="numberFormat('mobileNo')" @keypress="checkForNonNumericals($event)"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="tab-pane fade" :class="form.type == 2 ? 'show active': ''" id="user-id" role="tabpanel" aria-labelledby="user-id-tab">

                                                <div class="mobile-input-h">
                                                    <!-- <label for="">User ID</label> -->
                                                </div>
                                                <div class="user-password-wrapper">
                                                    <div class="password-box get-mob for">
                                                        <input type="text" class="form-control" @input="filterSpaces($event,'userId'),handleInputUserID"
                                                        :placeholder="translatedLangData('user-id','User ID')+'*'" v-model="form.userId" id="autoSizingInput" >
                                                        <div class="inp-img">
                                                            <img src="@/assets/images/user-login.webp" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="tab-content" id="pills-tabContent" v-else>

                                            <div class="tab-pane fade show active" id="user-id" role="tabpanel" aria-labelledby="user-id-tab">

                                                <div class="mobile-input-h">
                                                    <!-- <label for="">User ID</label> -->
                                                </div>
                                                <div class="user-password-wrapper">
                                                    <div class="password-box get-mob for">
                                                        <input type="text" class="form-control" @input="filterSpaces($event,'userId'),handleInputUserID"
                                                        :placeholder="translatedLangData('user-id','User ID')+'*'" v-model="form.userId">
                                                        <div class="inp-img">
                                                            <img src="@/assets/images/user-login-icons.webp" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="error-message-box">
                                        <p v-if="showValidationError && form.type == 1 && form.mobileNo == ''"
                                            class="wrong-message">
                                            {{ translatedLangData('please-enter-mobile-number','Please Enter Mobile Number') }}.</p>
                                        <p v-if="showValidationError && form.type == 2 && form.userId == ''"
                                            class="wrong-message">
                                            {{ translatedLangData('please-enter-user-id','Please Enter User ID') }}.</p>
                                    </div>

                                    <div class="form-container-sec">
                                        <div class="mobile-input-h">
                                            <!-- <label for="">Password*</label> -->
                                        </div>
                                        <div class="get-mob for">
                                            <input :type="passwordFieldType" class="form-control" id="inputPassword2" :placeholder="translatedLangData('password','Password')+'*'" v-model="form.password" @input="filterSpaces($event,'password')"  />
                                            <div class="inp-img" @click="togglePasswordVisibility()">
                                                <img v-if="passwordFieldType === 'password'" src="@/assets/images/passwordhideicon.webp" alt=""/>
                                                <img v-else src="@/assets/images/passwordviewicon.webp" alt=""/>
                                            </div>
                                        </div>

                                        <div class="error-message-box">
                                            <p v-if="showValidationError && form.password == ''" class="wrong-message">
                                                {{ translatedLangData('please-enter-password','Please Enter Password') }}.</p>
                                        </div>
                                    </div>

                                    <div class="check-box-sec">
                                        <div class="box-left">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="check1" name="option1"
                                                    value="something">
                                                <label class="form-check-label" for="check1">{{ translatedLangData('keep-me-logged-in','Keep Me Logged In?') }}</label>
                                            </div>
                                        </div>
                                        <div class="box-right" v-if="siteSettings && siteSettings?.business_type == 2">
                                            <div class="for-pass" @click="refreshData">
                                                <router-link :to="{ name: 'ForgetPassword' }">
                                                    {{ translatedLangData('forgot-password','Forgot Password?') }}
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="login-btn-option">

                                        <div class="form-group">
                                            <a href="JAvascript:void(0);" @click="demoUserLogin()"
                                                class="btn btn-submit btn-login demo-login-btn">
                                                {{ translatedLangData('demo-login','Login with Demo')}}
                                            </a>
                                        </div>

                                        <div class="form-group">
                                            <a href="JAvascript:void(0);" @click="signInMethodCall()"
                                                class="btn btn-submit btn-login">
                                                {{ translatedLangData('log-in','Login')}}
                                                <i class="ml-2 fas fa-sign-in-alt"></i>
                                            </a>
                                        </div>

                                    </div>

                                    


                                    <div class="other-login-option" v-if="siteSettings && siteSettings?.business_type == 2 && siteSettings?.social_link">
                                        <h3>{{ translatedLangData('or-login-with','Or Login With')}}</h3>
                                        <ul class="login-by-social-icon">
                                            <li v-if="siteSettings.social_link?.facebook_link">
                                                <a :href="siteSettings.social_link?.facebook_link" target="_blank">
                                                    <img src="@/assets/images/facebook.webp" alt="">
                                                </a>
                                            </li>
                                            <li v-if="siteSettings.social_link?.instagram_link">
                                                <a :href="siteSettings.social_link?.instagram_link" target="_blank">
                                                    <img src="@/assets/images/instagram.webp" alt="">
                                                </a>
                                            </li>
                                            <li v-if="siteSettings.social_link?.telegram_link">
                                                <a :href="siteSettings.social_link?.telegram_link" target="_blank">
                                                    <img src="@/assets/images/telegram.webp" alt="">
                                                </a>
                                            </li>
                                            <li v-if="siteSettings.social_link?.twitter_link">
                                                <a :href="siteSettings.social_link?.twitter_link" target="_blank">
                                                    <img src="@/assets/images/twitter.webp" alt="">
                                                </a>
                                            </li>
                                            <!-- <li>
                                                <a href="">
                                                    <img src="@/assets/images/google.webp" alt="">
                                                </a>
                                            </li> -->
                                        </ul>
                                    </div>

                                    <template v-if="siteSettings?.business_type == 2">
                                        <p class="whats-with-acc">{{ translatedLangData('dont-have-an-account',"Don’t Have An account?")}} &nbsp;
                                            <router-link :to="{ name: 'SignUp' }" @click="refreshData">{{ translatedLangData('register','Register')}}</router-link>
                                        </p>
                                    </template>
                                    
                                    <template v-if="siteSettings?.business_type == 2">
                                        <div class="whatsapp-login" v-if="siteSettings?.social_link?.whatsappno1">
                                            <h5>{{ translatedLangData('get-your-ready-made-id-from-whatsapp',"Get your ready-made ID from whatsapp")}}</h5>
                                            <button type="button" @click.prevent="openWhatsApp()">
                                                <img src="@/assets/images/whatsapp(1).webp" alt="">
                                                {{ translatedLangData('whatsapp-now',"WhatsApp Now")}}
                                            </button>
                                        </div>
                                    </template>

                                    

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';

import SuccessModal from '@/shared/components/modal/SuccessModal.vue';
import ErrorModal from '@/shared/components/modal/ErrorModal.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import { setHeaders } from '@/shared/services/headers';

export default {
    name: "Login",
    inject:['translatedLangData'],
    data() {
        return {
            form: {
                code: 91,
                mobileNo: "",
                userId: "",
                password: "",
                type: 1,
                captchaText: ''
            },
            showUserIdValidationError: false,
            showPasswordValidationError: false,
            showSuccessModal: false,
            successMsg: "",
            showErrorModal: false,
            errorMsg: "",
            loading: false,
            modal: null,
            showValidationError: false,
            captchaData: null,
            passwordFieldType: 'password'
        };
    },
    computed: {
        ...mapGetters({
            siteSettings: 'siteSettings'
        })
    },
    mounted() {
        if(localStorage.getItem('login_type') && this.siteSettings?.business_type == 2) {
            this.form.type = localStorage.getItem('login_type');
        }
    },
    methods: {
        togglePasswordVisibility() {            
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';            
        },
        handleInputUserID(event) {
            // this.form.userId = event.target.value.replace(/[^\w@#$%^&*()+\-*/]/g, ''); // Allow alphanumeric characters, underscore, and special characters like @#$%^&*()+-*/.
            const containsSpaces = /\s/.test(this.form.userId);
            if (containsSpaces) {
                this.form.userId = this.form.userId.replace(/\s/g, '');
            }
            event.target.value = this.form.userId;
        },
        filterSpaces(event, prop) {
            
            const containsSpaces = /\s/.test(this.form[prop]);
            if (containsSpaces) {
                this.form[prop] = this.form[prop].replace(/\s/g, '');
            }
        },
        numberFormat(prop) {
            // Convert the input to a string
            if (this.form[prop] != '') {
                let stringValue=''
                if(typeof this.form[prop]!='string'){
                    stringValue = this.form[prop].toString();
                }
                else{
                    stringValue = this.form[prop]
                }
                stringValue = stringValue.replace(/[^0-9]/g, '');
                stringValue = stringValue.replace(/[+-]/g, ''); // Replace '+' and '-' characters with an empty string
                // Convert the sanitized string back to a number
                if(typeof this.form[prop]!='string'){
                    this.form[prop] = stringValue === '' ? '' : parseInt(stringValue);
                }
                else{
                    this.form[prop] = stringValue
                }                
            }
        },
        checkForNonNumericals(event) {
            // Prevent input of the letter 'e'
            if (event.key.toLowerCase() === 'e') {
                event.preventDefault();
            }
            if (event.key === '-' || event.key === '+' || event.key === '.') {
                event.preventDefault();
            }
        },
        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                userId: "",
                password: "",
                type: this.form.type,
                captchaText: ''
            };
            this.showValidationError = false;
                this.passwordFieldType= 'password';
                this.showSuccessModal= false;
            this.successMsg= "";
            this.showErrorModal= false;
            this.errorMsg= "";
        },
        openWhatsApp() {
            window.open(`//wa.me/${this.siteSettings.social_link.whatsappno1}`, '_blank');
        },
        demoUserLogin() {
            this.form.type = 1;
            this.form.userId = "demouser";
            this.form.password = "demopass";
            this.loginServiceCall(apiName.DEMO_LOGIN_API)
        },
        signInMethodCall() {
            if (!this.loading) {
                let id = this.getId();
                if (id == "" || this.form.password == "") {
                    this.showValidationError = true;
                    this.showErrorModalFunc(this.translatedLangData('enter-the-valid-credentials','Please Enter The Valid Credentials'))
                }
                else {
                    this.loginServiceCall(apiName.LOGIN_API);
                }
            }
        },
        async loginServiceCall(url) {
            let id = this.getId();
            this.loading = true;
            this.$store.dispatch('setGlobalLoader', true);
            let data = {
                "userid": id.toString(),
                "type": this.siteSettings?.business_type == 1 ? 2 : this.form.type,
                "password": this.form.password,
                "cncode": this.form.code,
            };
            api.post(url, data).then(response => {
                this.loading = false;
                this.$store.dispatch('setGlobalLoader', false);
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        }
                        else {
                            this.showSuccessModalFunc(response.data.message);
                            let userData = response.data.data;
                            this.$cookies.set(AUTH_TOKEN, userData.token);
                            this.$store.dispatch('setToken', `Bearer ${userData.token}`);
                            window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                            axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                            localStorage.setItem('login_type', data.type)
                            this.$store.dispatch('setSiteVersion', null);
                            setHeaders();
                            this.setUserDataToStore(userData);
                            this.refreshData();
                            this.$router.go('/');
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        demoSignInCall() {
            this.loading = true;
            this.$store.dispatch('setGlobalLoader', true);
            api.post(apiName.DEMO_LOGIN_API, null).then(response => {
                this.loading = false;
				this.$store.dispatch('setGlobalLoader', false);
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        }
                        else {

                            this.showSuccessModalFunc(response.data.message);
                            let userData = response.data.data;
                            this.$cookies.set(AUTH_TOKEN, userData.token);
                            axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                            this.setUserDataToStore(userData);
                            this.refreshData();
                            this.$router.push({ name: 'Sports' });
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    console.log("Error Login : ", error);
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        getId() {
            if (this.siteSettings?.business_type == 2) {
                return this.form.type == 1 ? this.form.mobileNo : this.form.userId;
            } else {
                this.form.type = 2;
                return this.form.userId;
            }
        },
        setUserDataToStore(userData) {
            let asciiCodes = null;
            for (let i = 0; i < userData.userid.length; i++) {
                asciiCodes += userData.userid.charCodeAt(i)
            }
            let user = {
                balance: userData.balance,
                currency: userData.currency,
                currency_slug: userData.currency_slug,
                last_login_at: userData.last_login_at,
                name: userData.name,
                rid: userData.rid,
                userid: userData.userid,
                exposure: userData.expl,
                available_credits: userData.available_credits,
                asciiCodes: asciiCodes,
                id: userData.id,
                exp: userData.exp,
                activeWallet: userData.actw,
                cpi:userData.cpi,
                notificationSubscribed:null,
                notificationUnsubscribed:null,
            }
            this.$store.dispatch('setUser', user);
            this.$store.dispatch('setChips', userData.chips);
            this.$store.dispatch('setEventManage', userData.event_manage);
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    },
};
</script>

<style>form {
    margin-block-end: 1em;
}

.form-control.add::-webkit-inner-spin-button {
    display: none;
}</style>